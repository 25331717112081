import React, { useState } from 'react';
import { motion } from 'framer-motion'; // Importa Framer Motion
import Button from '../components/modal/Buttons';
import { useNavigate } from 'react-router-dom';
import SignUpModal from '../components/SignUpModal';
import nemuPosterIntroVideo from '../asset/Intro.png';
import TimeLine from '../components/TimeLine';
import nemuPosterMenuVideo from '../asset/Restaurant.png';
import nemuPosterConfVideo from '../asset/Cliente.jpg';
import { FaPizzaSlice, FaUtensils, FaBeer, FaCoffee, FaTiktok, FaFacebook, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import Lottie from 'react-lottie'; // Importa react-lottie
import comingSoonAnimation from '../asset/ComingSoon.json'; // Importa il file Lottie

const logoNemu = require('../asset/logo.png');

const italianProvinces = [
    "Agrigento", "Alessandria", "Ancona", "Aosta", "Arezzo", "Ascoli Piceno", "Asti",
    "Avellino", "Bari", "Barletta-Andria-Trani", "Belluno", "Benevento", "Bergamo",
    "Biella", "Bologna", "Bolzano", "Brescia", "Brindisi", "Cagliari", "Caltanissetta",
    "Campobasso", "Caserta", "Catania", "Catanzaro", "Chieti", "Como", "Cosenza",
    "Cremona", "Crotone", "Cuneo", "Enna", "Fermo", "Ferrara", "Firenze", "Foggia",
    "Forlì-Cesena", "Frosinone", "Genova", "Gorizia", "Grosseto", "Imperia", "Isernia",
    "L'Aquila", "La Spezia", "Latina", "Lecce", "Lecco", "Livorno", "Lodi", "Lucca",
    "Macerata", "Mantova", "Massa-Carrara", "Matera", "Messina", "Milano", "Modena",
    "Monza e Brianza", "Napoli", "Novara", "Nuoro", "Oristano", "Padova", "Palermo",
    "Parma", "Pavia", "Perugia", "Pesaro e Urbino", "Pescara", "Piacenza", "Pisa",
    "Pistoia", "Pordenone", "Potenza", "Prato", "Ragusa", "Ravenna", "Reggio Calabria",
    "Reggio Emilia", "Rieti", "Rimini", "Roma", "Rovigo", "Salerno", "Sassari", "Savona",
    "Siena", "Siracusa", "Sondrio", "Sud Sardegna", "Taranto", "Teramo", "Terni", "Torino",
    "Trapani", "Trento", "Treviso", "Trieste", "Udine", "Varese", "Venezia", "Verbano-Cusio-Ossola",
    "Vercelli", "Verona", "Vibo Valentia", "Vicenza", "Viterbo"
];

const HomePage = () => {
    const [isEligible, setIsEligible] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [signUp, setSignUp] = useState(false);
    const navigate = useNavigate();

    const [showRegistration, setShowRegistration] = useState(false);
    const restaurantUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/67082f7708d31f5a2be20467';
    const pizzeriaUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/67082f6f08d31f5a2be2036e';
    const barUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/671ec0082be7311e26ab64ef';
    const pubUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/671ebff82be7311e26ab62d9';

    const handleRegisterClick = () => {
        setShowRegistration(true);
    };

    const handleChangeRegistration = () => {
        setShowRegistration(!showRegistration);
    };

    const scrollToPromo = () => {
        const element = document.getElementById('configuratore');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToRoadmap = () => {
        const element = document.getElementById('roadmap');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Opzioni per l'animazione Lottie
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: comingSoonAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div>
            {/* Hero Section */}
            <section
                className="relative bg-cover bg-center w-full h-screen flex items-center justify-center"
                style={{ backgroundImage: `url('https://images.nemu-app.com/your-background-image.jpg')` }}
            >
                <div className="absolute inset-0 green-background"></div> {/* Overlay verde scuro */}
                <motion.div
                    className="relative z-10 text-center text-white px-4 mb-20"
                    initial={{ opacity: 0, y: -200 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <div className="flex flex-col md:flex-row justify-center items-center mb-4">
                        <h1 className="text-5xl md:text-6xl font-bold whitespace-nowrap">Benvenuto su</h1>
                        <img src={logoNemu} className="h-10 md:h-12 mt-4 md:mt-0 md:ml-4" alt="Logo Nemu" />
                    </div>


                    <Lottie
                        options={lottieOptions}

                        width="80%"
                    />
                    {/*
                    <div className="flex justify-center gap-4">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition"
                            onClick={scrollToPromo}
                        >
                            Scopri di più
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-transparent border-2 border-white text-white py-3 px-6 rounded-full hover:bg-white hover:text-green-500 transition"
                            onClick={handleRegisterClick}
                        >
                            Registrati
                        </motion.button>
                    </div>
                    */}
                </motion.div>
            </section>

            {/* Footer con icone social fisse in basso */}
            <footer className="fixed bottom-0 left-0 w-full">

                <footer className="fixed bottom-0 left-0 w-full">
                    <div className="flex justify-center items-center space-x-6 green-background py-4">
                        {/* Il testo sarà nascosto su schermi piccoli */}
                        <p className="hidden md:block text-xl md:text-2xl text-white">
                            Registrati al gruppo Facebook per restare aggiornato sugli sviluppi
                        </p>
                        <p className="block md:hidden text-xl text-white">
                            Per aggiornamenti registrati
                        </p>


                        <a href="https://www.facebook.com/groups/651143123944026" target="_blank" rel="noopener noreferrer" className="text-white">
                            <FaFacebook size={36} />
                        </a>
                        {/* <a href="https://www.instagram.com/nemu.app/" target="_blank" rel="noopener noreferrer" className="text-white">
                            <FaInstagram size={36} />
                        </a>
                        <a href="https://www.youtube.com/@Nem%C3%B9App-g7f" target="_blank" rel="noopener noreferrer" className="text-white">
                            <FaYoutube size={36} />
                        </a>
                        <a href="https://www.tiktok.com/@nemu.app?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer" className="text-white">
                            <FaTiktok size={36} />
                        </a> */}

                    </div>
                </footer>
            </footer>
        </div>
    );
};

export default HomePage;
