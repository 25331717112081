// src/App.js
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; 
import { logout } from './redux/loginsSlice';
import Dashboard from "./pages/Dashboard";
import Homepage from "./pages/Homepage";
import MenuDigitalePage from './pages/MenuDigitalePage';
import ProtectedRoutes from './middlewares/ProtectedRoutes';
import AuthSuccess from './pages/AuthSuccess';
import VerifyPageOwner from './pages/VerifyPageOwner';
import LoginPage from './pages/LoginPage';
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { useDispatch } from 'react-redux';

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

const AppContent = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('userLoggedIn'); // Rimosso JSON.parse
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Usa la funzione corretta
        const currentTime = Date.now() / 1000; // Tempo attuale in secondi
        if (decodedToken.exp < currentTime) {
          // Token scaduto
          dispatch(logout());
        }
      } catch (error) {
        // Token non valido
        dispatch(logout());
      }
    }
  }, [dispatch]);

  return (
    <div className="">
      {/* {!(pathname === '/sign-in' || pathname === '/sign-up') && (
        <div className="fixed green-background w-full h-12 top-0 z-40">
          <Navbar />
        </div>
      )} */}
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        {/* <Route exact path="/menu-digitale" element={<MenuDigitalePage />} /> */}
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/auth/success" element={<AuthSuccess />} />
        <Route exact path="/owners/verify" element={<VerifyPageOwner />} />

        <Route element={<ProtectedRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
